import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SwiftType from "../components/swift-type";

import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";

const TemplateService = ({ data, location, pageContext }) => {
  const { contentfulTemplateService: page } = data;

  const mainImage = page?.mainImage;
  const metaTitle = page.seoPageTitle ? page.seoPageTitle : page.pageTitle;
  const metaDesc = page.seoPageDescription
    ? page.seoPageDescription.seoPageDescription
    : null;
  const metaImage = page?.seoShareImage?.file?.url;

  const extra = { templateName: "services" };

  const header = page.header ? moduleConductor(page.header, 0, extra) : null;

  /* TODO: pull renderCarousels out into a module or component, like RelatedContentGroups or something,
    refactor so it isn't hardcoded to individual types */

  /*
  const renderCarousels = (subItems) => {
    const relatedServices = subItems.filter(service => service.__typename === 'ContentfulTemplateService');

    let carousels = [];

    if (relatedServices.length > 0) {
      const servicesUpdatedSlugs = relatedServices.map((service, i) => {
        let obj = { ...service, slug: `/services/${service.slug}` };
        return obj;
      });
      
      carousels.push({items: servicesUpdatedSlugs, title: 'Services'});
    }

    const carouselReturn = carousels.map((carousel, i) => {
      return (
        <div className="template-solution__related-services">
          <ModuleCarousel
            numDesktopSlides={3}
            cards={carousel.items}
            moduleHeadline={carousel.title}
            submenuTitle={carousel.title}
          />
        </div>
      );
    });

    return carouselReturn;
  }
  */

  const modules = page.modules
    ? page.modules.map((module, i) => {
      /*
        if (
          module.__typename === "ContentfulModuleCtaBanner" &&
          pageContext.relatedServices
        ) {
          // Confirmed BUG: if a CTA Banner doesn't exist, carousels don't render;
          return (
            <React.Fragment>
              {renderCarousels(pageContext.relatedServices)}
              {moduleConductor(module, i)}
            </React.Fragment>
          );
        }
      */

        return moduleConductor(module, i);
      })
    : null;

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  return (
    <Layout location={location}>
      <SEO
        title={`${metaTitle} | UnitedLex`}
        description={metaDesc}
        url={location.href}
        tags={["service"]}
        image={metaImage || mainImage?.file?.url}
      />
      <SwiftType
        customTitle={metaTitle}
        customDesc={metaDesc}
        customFeatImg={metaImage || mainImage?.file?.url}
        tags={["service"]}
      />
      {header}
      {modules}
      {/* {pageContext.relatedServices && (
        <ModuleCarousel cards={withUpdatedSlug} moduleHeadline={"Services"} />
      )} */}
    </Layout>
  );
};

export default TemplateService;

//query($slug: String!, $solutionTitle: String!) {
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTemplateService(slug: { eq: $slug }) {
      slug
      pageTitle
      doNotIndex
      seoPageTitle
      seoPageDescription {
        seoPageDescription
      }
      seoShareImage {
        ...ContentfulAssetFragment
      }
      header {
        ...ModuleHeaderSimpleFragment
        ...ModuleHeaderWithMediaFragment
      }
      modules {
        ...ModuleExpandingCardsFragment
        ...ModuleFeaturedBannerFragment
        ...ModuleTwoColumnCardsFragment
        ...ModuleThreeColumnCardsFragment
        ...ModuleSimpleSectionHeadingFragment
        ...ModuleRelatedContentFragment
        ...ModuleCtaBannerFragment
        ...ModuleCarouselFragment
      }
    }
  }
`;

// relatedInsightsBySolution: allContentfulTemplateInsight(
//   sort: { order: DESC, fields: publishDate }
//   filter: { relatedSolution: { pageTitle: { eq: $solutionTitle } } }
//   limit: 4
// ) {
//   nodes {
//     insightType {
//       insightType
//     }
//     publishDate
//   }
// }
